<script>
import axiosApiInstance from "@/utils/api";
const axios = require("axios");
export default {
  name: "JoinAccountAutologin",
  mounted() {

    if (this.$route.params.regtoken && this.$route.params.regtoken.length>10) {
      this.$store.commit('setRegToken', this.$route.params.regtoken )
    }

    axiosApiInstance.get(`/join-user-account/${this.$route.params.regtoken}`).then(resp =>{

      if (resp.status === 202 || resp.status === 200) {
       // let team_id = resp.data.account.id

        if (resp.data.user ){
          const u = resp.data.user
          this.$store.commit('setuser', u)
          this.errorMessage = "OK";
          localStorage.setItem('user-token', u.user_token)
          axios.defaults.headers.common['Authorization'] = `Bearer ${u.user_token}`
        }


        this.$router.push({path: `/profil`});
      }
    })



  }
}
</script>

<style scoped>

</style>
